import produce from 'immer';

const INITIAL_STATE = {
  data: [],
  cache: null,
};

export default function notas(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@notas/REQUEST_NOTAS_SUCCESS': {
        draft.data = action.payload;
        draft.cache = new Date().getTime() + 5 * 60 * 1000;
        break;
      }
      case '@auth/SIGN_OUT': {
        draft.data = [];
        draft.cache = null;
        break;
      }
      default:
    }
  });
}
