import React, { useEffect } from 'react';
import {
  Box,
  Chip,
  Container,
  Grid,
  Tooltip,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { DoneAll } from '@material-ui/icons';
import { darken } from 'polished';
import {
  setDocumentUploading,
  setLoading,
  uploadDocument,
} from '~/store/modules/documents/actions';
import Loading from '~/components/Loading';
import Upload from './Upload';

const useStyles = makeStyles((theme) => ({
  dropZone: {
    border: `4px dashed #BDC0D4`,
    borderRadius: '10px',
    height: '200px',
    margin: '15px 0',
    cursor: 'pointer',
    background: '#F7F8FA',
    padding: '10px',
  },
  internDrop: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  aside: {
    color: '#999',
    textAlign: 'center',
  },
  paside: {
    color: '#50A6FF',
    fontWeight: 'bold',
  },
  pasideu: {
    color: '#333',
  },
  option: {
    display: 'flex',
    justifyContent: 'space-between',
    color: theme.palette.success.main,
  },
  btEnviar: {
    background: '#50A6FF',
    borderRadius: '200px',
    color: '#fff',
    '&:hover': {
      background: darken(0.1, '#50A6FF'),
    },
  },
  divFile: {
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    background: 'rgba(80, 166, 255, 0.4)',
    padding: '11px',
    borderRadius: '8px',
  },
  fileName: {
    maxWidth: '20ch',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    color: 'rgba(80, 166, 255, 1)',
  },
  chipDocDone: {
    background: '#50A6FF',
    color: '#fff',
    '& .MuiChip-icon': {
      color: '#fff',
    },
    width: '100%',
  },
  chipDocMissing: {
    background: theme.palette.warning.main,
    width: '100%',
    color: '#fff',
    '& .MuiChip-icon': {
      color: '#fff',
    },
  },
  cardContentDone: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    padding: '10px',
    border: `2px solid #50A6FF`,
    color: '#50A6FF',
  },
  cardContentMissing: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    padding: '10px',
    border: `2px solid ${theme.palette.warning.main}`,
    color: theme.palette.warning.main,
  },
}));

function Documentos({ contrato_id, pessoa_id }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { documents, documentUploading, loadingMessage, loading } = useSelector(
    (state) => state.documents
  );
  const unidade = useSelector((state) => state.unidade.data);
  const contratos = useSelector((state) => state.contratos.data);

  const [docUploading, setDocUploading] = React.useState(documentUploading);
  const [documentsContrato, setDocumentsContrato] = React.useState(null);

  useEffect(() => {
    setDocUploading(documentUploading);
  }, [documentUploading]);

  useEffect(() => {
    const documentsFiltered = documents.filter(
      (doc) => doc.contrato_id === Number(contrato_id)
    );
    setDocumentsContrato((documentsFiltered && documentsFiltered[0]) || null);
  }, [contrato_id, documents]); // eslint-disable-line

  if (documentsContrato === null) {
    return (
      <Typography variant="body1" align="center">
        Sem documentos pendentes
      </Typography>
    );
  }

  const handleSend = (file, document) => {
    dispatch(setLoading(true, 'Iniciando upload...'));
    dispatch(setDocumentUploading(document.type, document.title, 0));
    const contratoAtual = contratos.dados.filter(
      (contrato) => contrato.contrato_id === Number(contrato_id)
    );
    dispatch(
      uploadDocument(
        file,
        document.type,
        document.subtype,
        document.title,
        contrato_id,
        contratoAtual[0].numerocontrato,
        pessoa_id,
        unidade.unidade_id
      )
    );
  };

  return (
    <Box>
      {loading && <Loading description={loadingMessage} />}
      <Container maxWidth="md">
        <Grid container spacing={1}>
          {documentsContrato.anexos
            .filter((document) => document.entregue)
            .map((document) => (
              <Grid item xs={3} key={document.type}>
                <Tooltip title={document.descricao}>
                  <Chip
                    label={document.descricao}
                    icon={<DoneAll fontSize="small" />}
                    className={classes.chipDocDone}
                  />
                </Tooltip>
              </Grid>
            ))}
          {documentsContrato.anexos
            .filter((document) => !document.entregue)
            .map((document) => (
              <Grid item xs={3} key={document.type}>
                <Upload
                  document={{
                    title: document.descricao,
                    type: document.type,
                    subtype: document.subtype,
                  }}
                  uploadPercentage={
                    docUploading.type === document.type
                      ? docUploading.uploadPercentage
                      : 0
                  }
                  uploadFile={handleSend}
                />
              </Grid>
            ))}
        </Grid>
      </Container>
    </Box>
  );
}

Documentos.propTypes = {
  contrato_id: PropTypes.string.isRequired,
  pessoa_id: PropTypes.number.isRequired,
};

export default Documentos;
