import React, { useEffect } from 'react';
import DataGrid, {
  Column,
  Pager,
  Paging,
  Grouping,
  GroupPanel,
  SearchPanel,
  MasterDetail,
} from 'devextreme-react/data-grid';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid } from '@material-ui/core';
import Breadcrumb from '~/components/CustomUI/CustomBreadcrumbs';
import { useDispatch, useSelector } from 'react-redux';
import { getNotas } from '~/store/modules/notas/actions';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
  tituloFuncionalidade: {
    textTransform: 'uppercase',
    color: '#3E98C7',
    fontWeight: 'bold',
    marginBottom: '30px',
  },
  grids: {
    marginTop: '15px',
  },
  showFiltros: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
});

const NotasDetails = ({ data }) => {
  const { data: curso } = data;

  const materiasCursadas = curso.materias.filter((materia) =>
    Boolean(materia.aulas)
  );

  if (materiasCursadas.length === 0) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Typography variant="body1" color="secondary" align="center">
          Sem notas disponíveis
        </Typography>
      </div>
    );
  }

  return (
    <>
      <DataGrid
        dataSource={materiasCursadas}
        showBorders
        noDataText="Sem dados disponíveis"
      >
        <MasterDetail
          enabled
          component={({ data: materiaCursadas }) => {
            const { data: materia } = materiaCursadas;
            return (
              <DataGrid
                dataSource={materia.notas}
                showBorders
                noDataText="Sem dados disponíveis"
              >
                <Column
                  caption="DESCRIÇÃO"
                  dataField="descricao"
                  alignment="center"
                />
                <Column caption="NOTA" dataField="nota" alignment="center" />
              </DataGrid>
            );
          }}
        />
        <Column caption="MATÉRIA" dataField="materia" alignment="center" />
        <Column caption="PROFESSOR" dataField="professor" alignment="center" />
        <Column caption="PRESENÇAS" dataField="presencas" alignment="center" />
        <Column
          caption="% FREQUÊNCIA"
          dataField="frequencia"
          alignment="center"
        />
      </DataGrid>
    </>
  );
};

NotasDetails.propTypes = {
  data: PropTypes.shape().isRequired,
};

export default function Notas() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { cache, data } = useSelector((state) => state.notas);

  useEffect(() => {
    if (!cache || new Date() - new Date(cache) > 60000) {
      dispatch(getNotas());
    }
  }, [cache, data]); //eslint-disable-line

  function prepareCell(e) {
    if (e.rowType === 'data') {
      if (!e.column.caption) {
        return;
      }

      if (e.column.caption === 'NOTA') {
        e.cellElement.innerText = e.value ? Math.round(e.value * 10) / 10 : '-';
      }
      if (e.column.caption.includes('FREQUÊNCIA')) {
        e.cellElement.innerText = e.value ? e.value : '-';
      }
      if (e.column.caption.includes('PRESENÇAS')) {
        e.cellElement.innerText = e.value ? e.value : '-';
      }
    }
  }

  return (
    <>
      <Breadcrumb funcionalidade="Notas e frequência" />
      <Grid container>
        <Grid item xs={6}>
          <Typography variant="h6" className={classes.tituloFuncionalidade}>
            Notas e frequência
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.grids}>
        <DataGrid
          dataSource={data}
          allowColumnReordering
          showBorders
          hoverStateEnabled
          noDataText="Sem dados disponíveis"
          onCellPrepared={(e) => {
            prepareCell(e);
          }}
        >
          <GroupPanel visible emptyPanelText="" />
          <SearchPanel visible placeholder="Filtrar" />
          <Grouping autoExpandAll={false} />
          <Paging defaultPageSize={10} />
          <Pager showInfo infoText="Página {0} de {1} ({2} aulas)" />

          <MasterDetail enabled component={NotasDetails} />
          <Column caption="CURSO" dataField="curso" alignment="center" />
          <Column
            caption="CONTRATO"
            dataField="numerocontrato"
            alignment="center"
          />
          <Column caption="AULAS" dataField="aulas" alignment="center" />
        </DataGrid>
      </Grid>
    </>
  );
}
