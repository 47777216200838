export function getNotas() {
  return {
    type: '@notas/REQUEST_NOTAS',
  };
}

export function getNotasSuccess(notas) {
  return {
    type: '@notas/REQUEST_NOTAS_SUCCESS',
    payload: notas,
  };
}

export function getNotasFailure() {
  return {
    type: '@notas/REQUEST_NOTAS_FAILURE',
  };
}
