import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  Grid,
  Toolbar,
  IconButton,
  Hidden,
  Drawer,
  CssBaseline,
  AppBar,
} from '@material-ui/core';
import {
  makeStyles,
  createTheme,
  ThemeProvider,
} from '@material-ui/core/styles';
import { IoMdMenu } from 'react-icons/io';
import { ConfirmProvider } from 'material-ui-confirm';

import { limparConteudo as limparConteudoCursos } from '~/store/modules/aula/actions';
import { limparConteudo as limparConteudoVejaEmCasa } from '~/store/modules/vejaemcasa/actions';
import Menu from '~/components/Menu';
import Notificacoes from '~/components/Notificacoes';
import FaleConosco from '~/components/FaleConosco';
import DocumentsPendingButton from '~/components/DocumentsPendingButton';
import Profile from '~/components/Profile';
import Loading from '~/components/Loading';

import { useLoading } from '~/hooks/loading';
import { getContratoLabels } from '~/store/modules/funcionalidades/actions';

// import schoolTheme from '~/styles/theme';

export default function DefaultLayout({ children, container }) {
  const dispatch = useDispatch();

  const instituicao = useSelector((state) => state.instituicao.data);
  const labels = useSelector((state) => state.funcionalidades.labels);

  const { loadingShowed } = useLoading();
  const drawerWidth = 240;
  const pathname = window.location.pathname.split('/')[1];
  const menuDefaultHide =
    pathname === 'conteudos' ||
    pathname === 'veja-em-casa' ||
    pathname === 'video-aulas' ||
    pathname === 'cursos';

  const [mobileOpen, setMobileOpen] = useState(false);

  useEffect(() => {
    if (!labels) {
      dispatch(getContratoLabels());
    }
  }, [labels]); // eslint-disable-line

  useEffect(() => {
    if (!menuDefaultHide) {
      setMobileOpen(false);
    }
  }, [menuDefaultHide]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
    },
    drawer: {
      [theme.breakpoints.up('md')]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    appBar: {
      [theme.breakpoints.up('md')]: {
        marginLeft: drawerWidth,
        width: `${!menuDefaultHide && `calc(100% - ${drawerWidth}px)`}`,
      },
      background: '#fff',
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        display: `${!menuDefaultHide && 'none'}`,
      },
    },
    toolbar: {
      minHeight: '60px',
      display: 'flex',
      justifyContent: 'center',
    },
    drawerPaper: {
      width: drawerWidth,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(2),
      flexWrap: 'unset',
    },
    toolbarButtons: {
      display: 'flex',
      justifyContent: 'flex-end',
      flexDirection: 'row',
      width: '100%',
      alignItems: 'center',
    },
  }));

  const theme = createTheme({
    palette: {
      primary: {
        main: instituicao.primary_color,
        contrastText: '#fff',
      },
      secondary: {
        main: '#868CAB',
      },
    },
    typography: {
      fontFamily: 'Roboto, sans-serif',
    },
    overrides: {
      MuiStepIcon: {
        root: {
          fontSize: '40px',
          color: 'rgba(234, 236, 245, 0.95)',
        },
        text: {
          fill: '#fff',
        },
      },
      MuiBadge: {
        anchorOriginTopRightRectangle: {
          top: 5,
          right: -10,
        },
      },
      MuiBackdrop: {
        root: {
          backgroundColor: 'none',
        },
      },
    },
  });

  const classes = useStyles();

  if (pathname !== 'cursos') {
    dispatch(limparConteudoCursos());
  }

  if (pathname !== 'veja-em-casa') {
    dispatch(limparConteudoVejaEmCasa());
  }

  return (
    <ThemeProvider theme={theme}>
      <ConfirmProvider
        defaultOptions={{
          confirmationButtonProps: { autoFocus: true },
        }}
      >
        <div className={classes.root}>
          <CssBaseline />
          <AppBar position="fixed" className={classes.appBar}>
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                className={classes.menuButton}
              >
                <IoMdMenu color="#333" />
              </IconButton>
              <div className={classes.toolbarButtons}>
                <DocumentsPendingButton />
                {instituicao.fale_conosco && <FaleConosco />}
                <Notificacoes />
                <Profile />
              </div>
            </Toolbar>
          </AppBar>
          {!menuDefaultHide && (
            <nav className={classes.drawer}>
              <Hidden mdUp implementation="css">
                <Drawer
                  container={container}
                  variant="temporary"
                  anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                  open={!menuDefaultHide && mobileOpen}
                  onClose={handleDrawerToggle}
                  classes={{
                    paper: classes.drawerPaper,
                  }}
                  ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                  }}
                  elevation={1}
                >
                  <Menu />
                </Drawer>
              </Hidden>
              <Hidden smDown implementation="css">
                <Drawer
                  classes={{
                    paper: classes.drawerPaper,
                  }}
                  variant="permanent"
                  open={!menuDefaultHide}
                  elevation={1}
                >
                  <Menu />
                </Drawer>
              </Hidden>
            </nav>
          )}
          {menuDefaultHide && (
            <Drawer
              container={container}
              variant="temporary"
              anchor={theme.direction === 'rtl' ? 'right' : 'left'}
              open={menuDefaultHide && mobileOpen}
              onClose={handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper,
              }}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
              elevation={1}
            >
              <Menu />
            </Drawer>
          )}
          <Grid
            container
            spacing={0}
            direction="column"
            style={{ minHeight: '100vh' }}
            className={classes.content}
          >
            <div className={classes.toolbar} />
            {children}
            {loadingShowed && <Loading />}
          </Grid>
        </div>
      </ConfirmProvider>
    </ThemeProvider>
  );
}

DefaultLayout.propTypes = {
  children: PropTypes.element.isRequired,
  container: PropTypes.instanceOf(
    typeof Element === 'undefined' ? Object : Element
  ),
};

DefaultLayout.defaultProps = {
  container: null,
};
