import produce from 'immer';

const INITIAL_STATE = {
  loading: false,
  loadingMessage: '',
  loaded: false,
  documents: [],
  error: false,
  documentUploading: {
    type: null,
    subtype: null,
    title: null,
    uploadPercentage: 0,
  },
  contrato: null,
};

export default function documents(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@documents/FETCH': {
        draft.error = false;
        break;
      }
      case '@documents/FETCH_FAILURE': {
        draft.error = true;
        draft.loaded = true;
        break;
      }
      case '@documents/FETCH_SUCCESS': {
        draft.error = false;
        draft.loaded = true;
        draft.documents = action.payload.documents;
        break;
      }
      case '@documents/REMOVE_ANEXOS_FALTANTES': {
        const contractIndex = draft.documents.findIndex(
          (document) =>
            document.contrato_id === Number(action.payload.contrato_id)
        );
        const attachIndex = draft.documents[contractIndex].anexos.findIndex(
          (document) => document.type === Number(action.payload.typeDocument)
        );
        const newAnexos = [...draft.documents[contractIndex].anexos];
        newAnexos[attachIndex].entregue = true;
        const newDocuments = [...draft.documents];
        newDocuments[contractIndex].anexos = newAnexos;
        draft.documents = newDocuments;
        break;
      }
      case '@documents/SET_LOADING': {
        draft.loading = action.payload.loading;
        draft.loadingMessage = action.payload.message;
        break;
      }
      case '@documents/SET_UPLOAD_PERCENTAGE': {
        draft.documentUploading = {
          ...draft.documentUploading,
          uploadPercentage: action.payload.uploadPercentage,
        };
        break;
      }
      case '@documents/SET_DOCUMENT_UPLOADING': {
        draft.documentUploading = {
          type: action.payload.type,
          title: action.payload.title,
          uploadPercentage: 0,
        };
        break;
      }
      case '@auth/SIGN_OUT': {
        draft.loading = false;
        draft.error = false;
        draft.loaded = false;
        draft.documentUploading = {
          type: null,
          title: null,
          subtype: null,
          uploadPercentage: 0,
        };
        draft.contrato = null;
        break;
      }
      default:
    }
  });
}
