import { all, call, fork, put, takeLatest, take } from 'redux-saga/effects';
import { eventChannel, END } from 'redux-saga';
import api from '~/services/api';
import apiBackendF10 from '~/services/apiBackendF10';
import { toast } from 'react-toastify';
import {
  fetchDocumentsFailure,
  fetchDocumentsSuccess,
  removeAnexosFaltantes,
  setLoading,
  setDocumentUploading,
  setPercentageUpload,
} from './actions';

export function* fetchDocuments({ payload }) {
  try {
    const response = yield call(api.get, `/contrato/documents`, {
      params: { contratos: payload.contratos },
    });

    yield put(fetchDocumentsSuccess(response.data || []));
  } catch (err) {
    yield put(fetchDocumentsFailure());
  }
}

function createUploader(file, url) {
  let emit;
  const chan = eventChannel((emitter) => {
    emit = emitter;
    return () => {};
  });
  const uploadProgressCb = ({ total, loaded }) => {
    const percentage = Math.round((loaded * 100) / total);
    emit(percentage);
    if (percentage === 100) emit(END);
  };
  const uploadPromise = apiBackendF10.put(url, file, {
    headers: {
      'Content-Type': 'application/pdf',
    },
    onUploadProgress: uploadProgressCb,
  });
  return [uploadPromise, chan];
}

function* uploadProgressWatcher(chan) {
  while (true) {
    const progress = yield take(chan);
    yield put(setPercentageUpload(progress));
  }
}

export function* uploadDocument({ payload }) {
  try {
    yield put(setLoading(true, 'Gerando autenticação...'));
    const responseAuth = yield call(
      apiBackendF10.post,
      `/unidade/anexos/contratoLinkUpload`,
      {
        unidade_id: payload.unidade_id,
        contrato_id: payload.contrato_id,
        pessoa_id: payload.pessoa_id,
        description: `${payload.lookupTitle}-${payload.contrato_id}`,
        subtype: payload.lookupDocumentSubtype,
        type: payload.lookupDocument,
        file_extension: 1,
        size: payload.file.byteLength,
      }
    );

    const { link_id, urlUpload } = responseAuth.data;

    yield put(setLoading(false, ''));
    yield put(setPercentageUpload(1));

    const [uploadPromise, chan] = yield call(
      createUploader,
      payload.file,
      urlUpload
    );
    yield fork(uploadProgressWatcher, chan);

    yield call(() => uploadPromise);

    const responseConfirm = yield call(
      apiBackendF10.post,
      '/unidade/anexos/confirmLinkUpload',
      {
        link_id,
        unidade_id: payload.unidade_id,
      }
    );

    if (!responseConfirm.data.result) {
      toast.error('Falha ao confirmar upload do arquivo.');
      return;
    }

    yield put(setDocumentUploading(null, null));
    yield put(
      removeAnexosFaltantes(payload.lookupDocument, payload.contrato_id)
    );
    toast.success('Arquivo enviado com sucesso!');
  } catch (err) {
    yield put(fetchDocumentsFailure());
  } finally {
    yield put(setLoading(false, ''));
  }
}

export default all([
  takeLatest('@documents/FETCH', fetchDocuments),
  takeLatest('@documents/UPLOAD', uploadDocument),
]);
