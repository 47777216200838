export function fetchDocuments(contratos, isContractDetail = false) {
  return {
    type: '@documents/FETCH',
    payload: { contratos, isContractDetail },
  };
}

export function fetchDocumentsSuccess(documents) {
  return {
    type: '@documents/FETCH_SUCCESS',
    payload: { documents },
  };
}

export function fetchDocumentsFailure() {
  return {
    type: '@documents/FETCH_FAILURE',
  };
}

export function uploadDocument(
  file,
  lookupDocument,
  lookupDocumentSubtype,
  lookupTitle,
  contrato_id,
  numero_contrato,
  pessoa_id,
  unidade_id
) {
  return {
    type: '@documents/UPLOAD',
    payload: {
      file,
      lookupDocument,
      lookupDocumentSubtype,
      lookupTitle,
      contrato_id,
      numero_contrato,
      pessoa_id,
      unidade_id,
    },
  };
}

export function setLoading(loading, message) {
  return {
    type: '@documents/SET_LOADING',
    payload: { loading, message },
  };
}

export function removeAnexosFaltantes(typeDocument, contrato_id) {
  return {
    type: '@documents/REMOVE_ANEXOS_FALTANTES',
    payload: { typeDocument, contrato_id },
  };
}

export function setPercentageUpload(uploadPercentage) {
  return {
    type: '@documents/SET_UPLOAD_PERCENTAGE',
    payload: { uploadPercentage },
  };
}

export function setDocumentUploading(type, title) {
  return {
    type: '@documents/SET_DOCUMENT_UPLOADING',
    payload: { type, title },
  };
}
