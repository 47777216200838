import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Fade from '@material-ui/core/Fade';
import Image from 'material-ui-image';
import MatriculaRealizada from '~/assets/ilustra_matricula.svg';
import history from '~/services/history';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    height: '507px',
    width: '420px',
    borderRadius: '20px',
  },
  msgSucesso: {
    color: '#47D19D',
    fontSize: '24px',
  },
  lbContrato: {
    color: '#868CAB',
    fontSize: '16px',
    fontWeight: 'bold',
  },
  numeroContrato: {
    color: '#47D19D',
  },
  txComecar: {
    color: '#868CAB',
    fontSize: '19px',
    margin: '25px 10px',
  },
  btComecar: {
    color: '#fff',
    borderRadius: '200px',
  },
}));

export default function ModalConclusao({ open, setOpen, contrato }) {
  const classes = useStyles();

  function handleClick() {
    setOpen(false);
    history.push('/conteudos');
  }

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        closeAfterTransition
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <Image
              src={MatriculaRealizada}
              style={{
                display: 'flex',
                paddingTop: 'unset',
                marginRight: '20px',
              }}
              imageStyle={{
                width: 'unset',
                height: 'unset',
                position: 'unset',
                maxHeight: '255px',
              }}
            />
            <Typography align="center" className={classes.msgSucesso}>
              Matrícula efetuada com sucesso
            </Typography>
            <Typography align="center" className={classes.lbContrato}>
              Contrato número{' '}
              <span className={classes.numeroContrato}>
                {contrato.numerocontrato}
              </span>
            </Typography>
            <Typography align="center" className={classes.txComecar}>
              Você pode começar a ver os conteúdos de aula ;)
            </Typography>
            <Button
              className={classes.btComecar}
              variant="contained"
              fullWidth
              color="primary"
              type="button"
              onClick={() => {
                handleClick();
              }}
            >
              Começar
            </Button>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

ModalConclusao.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  contrato: PropTypes.shape().isRequired,
};
