import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ArrowBack from '@material-ui/icons/ArrowBack';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import { AiOutlinePrinter, AiOutlineDownload } from 'react-icons/ai';
import { format } from 'date-fns';
import pt from 'date-fns/locale/pt';
import DadosPessoais from '~/components/Contrato/DadosPessoais';
import DadosCurso from '~/components/Contrato/DadosCurso';
import TermosContrato from '~/components/Contrato/TermosContrato';
import Breadcrumb from '~/components/CustomUI/CustomBreadcrumbs';
import history from '~/services/history';
import api from '~/services/api';
import { apiCallHandler } from '~/services/callWrapper';
import { useQuery } from '~/hooks/query';
import Documentos from '~/components/Contrato/Documentos';

const useStyles = makeStyles((theme) => ({
  tituloFuncionalidade: {
    textTransform: 'uppercase',
    color: '#3E98C7',
    fontWeight: 'bold',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
  },
  cardHeader: {
    padding: theme.spacing(3, 5),
    background: '#50A6FF',
  },
  captionHeader: {
    fontWeight: 'bold',
    color: 'rgba(255, 255, 255, 0.46)',
    fontSize: '11px',
  },
  infoHeader: {
    color: '#fff',
    fontSize: '20px',
    letterSpacing: '0.15px',
    fontWeight: 500,
  },
  numeroContrato: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    [theme.breakpoints.down('xs')]: {
      marginTop: '10px',
      alignItems: 'flex-start',
    },
  },
  infoAceite: {
    color: '#fff',
  },
}));

export default function DetalhesContrato() {
  const classes = useStyles();
  const params = useParams();
  const query = useQuery();

  const contratos = useSelector((state) => state.contratos.data);
  const labels = useSelector((state) => state.funcionalidades.labels);

  const { contrato: id } = params;

  const [contrato, setContrato] = useState(null);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [dadosPessoais, setDadosPessoais] = useState(null);
  const [dadosCurso, setDadosCurso] = useState(null);
  const [termosUso, setTermosUso] = useState(null);
  const [dadosPagamento, setDadosPagamento] = useState(null);
  const [dadosContratada, setDadosContratada] = useState(null);
  const [dadosInfoAceite, setDadosInfoAceite] = useState(null);
  const [documentoPdf, setDocumentoPdf] = useState(null);
  const [value, setValue] = useState(
    (Boolean(query.get('action')) && Number(query.get('action'))) || 0
  );

  function handleChange(event, newValue) {
    setValue(newValue);
  }

  useEffect(() => {
    async function getDadosPagamento() {
      const response = await apiCallHandler(
        api.get,
        `dados-pagamento/${contrato.contrato_id}`
      );
      setDadosPagamento(response.data.dadosPagamento);
    }

    async function getDadosCurso() {
      const response = await apiCallHandler(
        api.get,
        `dados-curso/${contrato.contrato_id}`
      );
      setDadosCurso(response.data.dadosCurso);
    }

    async function getTermosContrato() {
      const response = await apiCallHandler(
        api.get,
        `termos/${contrato.termouso_id}`
      );
      setTermosUso(response.data.termos);
    }

    async function getDadosPessoais() {
      let url = `dados-pessoais/?aluno=${contrato.aluno_id}`;
      if (contrato && contrato.temtitular) {
        url += `&titular=${contrato.titular_id}`;
      }
      const response = await apiCallHandler(api.get, url);
      setDadosPessoais(response.data);
    }

    async function getInfoAceite() {
      const response = await apiCallHandler(
        api.get,
        `info-aceite/${contrato.contrato_id}`
      );
      setDadosInfoAceite(response.data.dadosAceite);
    }

    async function getDadosContrada() {
      const response = await apiCallHandler(api.get, `contratada`);
      setDadosContratada(response.data.dadosContratada);
    }

    async function getPdf() {
      const response = await apiCallHandler(
        api.get,
        `/contrato/${contrato.contrato_id}/file`
      );

      setDocumentoPdf((response.data && response.data.link) || null);
    }

    if (contratos && !contrato) {
      const contratoFiltered = contratos.dados.filter((contratoFilter) => {
        return contratoFilter.contrato_id === parseInt(id, 10);
      });

      setContrato(contratoFiltered[0]);
    }

    if (contrato && !dataLoaded) {
      setDataLoaded(true);
      if (!dadosPagamento) {
        getDadosPagamento();
      }

      if (!dadosCurso) {
        getDadosCurso();
      }

      if (contrato.termouso_id && !termosUso) {
        getTermosContrato();
      }

      if (!dadosPessoais) {
        getDadosPessoais();
      }

      if (!dadosContratada) {
        getDadosContrada();
      }

      if (!contrato.pendenteaceite) {
        getInfoAceite();
      }

      if (!documentoPdf) {
        getPdf();
      }
    }
  }, [
    contrato,
    contratos,
    dadosContratada,
    dadosCurso,
    dadosInfoAceite,
    dadosPagamento,
    dadosPessoais,
    documentoPdf,
    id,
    dataLoaded,
    termosUso,
  ]);

  if (!contratos) {
    return null;
  }

  function getDataFormatada(data) {
    return format(
      new Date(data.replace(/\.\d+Z/gi, '')),
      `dd/MM/yyyy à's' HH:mm`,
      {
        locale: pt,
      }
    );
  }

  function downloadContrato() {
    fetch(documentoPdf, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/pdf',
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${new Date().getTime()}.pdf`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      });
  }

  return (
    <>
      {contrato && (
        <Breadcrumb categorias={['CONTRATO']} funcionalidade={contrato.curso} />
      )}
      <Typography
        variant="h6"
        className={classes.tituloFuncionalidade}
        onClick={() => {
          history.push('/contratos');
        }}
      >
        <ArrowBack /> Voltar para os contratos
      </Typography>
      <Paper className={classes.cardHeader}>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <Typography
              component="p"
              variant="caption"
              className={classes.captionHeader}
            >
              {(labels && labels.contrato.singular.toUpperCase()) || 'CURSO'}
            </Typography>
            <Typography className={classes.infoHeader}>
              {contrato && contrato.curso}
            </Typography>
            {dadosInfoAceite && (
              <Typography variant="caption" className={classes.infoAceite}>{`${
                dadosInfoAceite.obs
              } Em ${getDataFormatada(dadosInfoAceite.data)}.`}</Typography>
            )}
          </Grid>
          <Grid item xs={12} sm={6} className={classes.numeroContrato}>
            <Typography
              component="p"
              variant="caption"
              className={classes.captionHeader}
            >
              N° DO CONTRATO
            </Typography>
            <Typography className={classes.infoHeader}>
              {contrato && contrato.numerocontrato}
            </Typography>
            {documentoPdf && (
              <div>
                <IconButton color="primary" onClick={() => downloadContrato()}>
                  <AiOutlineDownload color="#fff" />
                </IconButton>

                <IconButton
                  color="primary"
                  onClick={() => window.open(documentoPdf, '_blank')}
                >
                  <AiOutlinePrinter color="#fff" />
                </IconButton>
              </div>
            )}
          </Grid>
        </Grid>
      </Paper>
      <Paper>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          <Tab label="Dados Pessoais" />
          <Tab label="Documentos" />
          <Tab label={`Dados do ${labels.contrato.singular}`} />
          {contrato && contrato.termouso_id && (
            <Tab label="Termos do Contrato" />
          )}
        </Tabs>
        <div
          role="tabpanel"
          hidden={value !== 0}
          id={`scrollable-auto-tabpanel-${0}`}
          aria-labelledby={`scrollable-auto-tab-${0}`}
        >
          <Box p={3}>
            {contrato && (
              <DadosPessoais
                contrato={contrato}
                dadosPessoais={dadosPessoais}
                setDadosPessoais={setDadosPessoais}
              />
            )}
          </Box>
        </div>
        <div
          role="tabpanel"
          hidden={value !== 1}
          id={`scrollable-auto-tabpanel-${1}`}
          aria-labelledby={`scrollable-auto-tab-${1}`}
        >
          <Box p={3}>
            {dadosPessoais && (
              <Documentos
                contrato_id={id}
                pessoa_id={dadosPessoais.dadosPessoais.pessoa_id}
              />
            )}
          </Box>
        </div>
        <div
          role="tabpanel"
          hidden={value !== 2}
          id={`scrollable-auto-tabpanel-${2}`}
          aria-labelledby={`scrollable-auto-tab-${2}`}
        >
          <Box p={3}>
            {contrato && (
              <DadosCurso
                contrato={contrato}
                dadosCurso={dadosCurso}
                setDadosCurso={setDadosCurso}
                dadosPagamento={dadosPagamento}
              />
            )}
          </Box>
        </div>
        {contrato && contrato.termouso_id && (
          <div
            role="tabpanel"
            hidden={value !== 3}
            id={`scrollable-auto-tabpanel-${3}`}
            aria-labelledby={`scrollable-auto-tab-${3}`}
          >
            <Box p={3}>
              {contrato && (
                <TermosContrato
                  contrato={contrato}
                  termosUso={termosUso}
                  setTermosUso={setTermosUso}
                  contratada={dadosContratada}
                  setContratada={setDadosContratada}
                />
              )}
            </Box>
          </div>
        )}
      </Paper>
    </>
  );
}
