import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  CardContent,
  Chip,
  Fade,
  makeStyles,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { CloudUpload } from '@material-ui/icons';
import clsx from 'clsx';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

const useStyles = makeStyles((theme) => ({
  chip: {
    background: theme.palette.warning.main,
    width: '100%',
    color: '#fff',
    '& .MuiChip-icon': {
      color: '#fff',
    },
    '&.MuiChip-clickable:hover, &.MuiChip-clickable:focus': {
      background: theme.palette.warning.dark,
    },
    transition: 'border-radius 0.5s',
  },
  chipOpen: {
    borderRadius: '16px 16px 0 0',
    transition: 'border-radius 0.5s',
  },
  cardContentMissing: {
    background: theme.palette.warning.main,
    color: '#fff',
    borderRadius: '0 0 16px 16px',
    padding: '2px',
  },
  input: {
    display: 'none',
  },
  cardContentFile: {
    background: '#f5f5f5',
    borderRadius: '0 0 16px 16px',
    color: '#000',
    '&.MuiCardContent-root:last-child': {
      padding: '10px',
    },
  },
  button: {
    borderRadius: '16px',
    background: theme.palette.warning.main,
    color: '#fff',
    '&:hover': {
      background: theme.palette.warning.dark,
    },
  },
}));

const schema = Yup.object().shape({
  size: Yup.number()
    .max(10000, 'O arquivo excede o tamanho máximo permitido de 10Mb')
    .required(),
  type: Yup.string().oneOf(
    [
      'application/pdf',
      'image/png',
      'image/jpeg',
      'image/jpg',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    ],
    'Tipo de arquivo inválido'
  ),
});

const Upload = ({ document, uploadFile, uploadPercentage }) => {
  const classes = useStyles();
  const [file, setFile] = useState(null);
  const inputRef = useRef(null);

  const handleFileChange = (e) => {
    if (e.target.files.length === 0) {
      return;
    }

    const { type, size } = e.target.files[0];
    schema
      .validate({ size: size / 1024, type })
      .then(() => {
        setFile(e.target.files[0]);
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  const handleUpload = () => {
    if (!file) {
      return;
    }
    uploadFile(file, document);
  };

  const onClickUpload = () => {
    inputRef.current.click();
  };

  const handleDelete = () => {
    setFile(null);
  };

  return (
    <>
      <input
        type="file"
        onChange={handleFileChange}
        ref={inputRef}
        className={classes.input}
        accept=".pdf, .doc, .docx, .png, .jpg, .jpeg"
        capture
      />
      <Tooltip title={document.title}>
        <Chip
          label={document.title}
          icon={<CloudUpload fontSize="small" />}
          className={clsx(classes.chip, file && classes.chipOpen)}
          onClick={(uploadPercentage <= 0 && onClickUpload) || null}
          onDelete={file && handleDelete}
        />
      </Tooltip>
      {file && (
        <Fade in={Boolean(file)} timeout={1000}>
          <Card className={classes.cardContentMissing}>
            <CardContent className={classes.cardContentFile}>
              <Typography variant="caption" component="p" align="center">
                {file.name}
              </Typography>
              <Typography variant="caption" component="p" align="center">
                {(file.size / 1000).toFixed(0)}kb
              </Typography>
              <Button
                size="small"
                fullWidth
                variant="outlined"
                onClick={handleUpload}
                className={classes.button}
                disabled={uploadPercentage > 0}
              >
                {uploadPercentage > 0
                  ? `Enviando... ${uploadPercentage}%`
                  : 'Enviar'}
              </Button>
            </CardContent>
          </Card>
        </Fade>
      )}
    </>
  );
};

Upload.propTypes = {
  document: PropTypes.shape({
    type: PropTypes.number.isRequired,
    subtype: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
  uploadPercentage: PropTypes.number,
  uploadFile: PropTypes.func.isRequired,
};

Upload.defaultProps = {
  uploadPercentage: 0,
};

export default Upload;
