import { toast } from 'react-toastify';
import { all, put, takeLatest } from 'redux-saga/effects';
import api from '~/services/api';
import { callHandler } from '~/services/callWrapper';
import { getNotasFailure, getNotasSuccess } from './actions';

const transformResultToView = (obj) => {
  const transformedMaterias = obj.materias.reduce((acc, materia) => {
    const existingMateria = acc.find(
      (m) => m.materia_id === materia.materia_id
    );
    if (existingMateria) {
      existingMateria.notas.push({
        nota: materia.nota,
        descricao: materia.descricao,
      });
    } else {
      acc.push({
        aulas: materia.aulas,
        media: materia.media,
        turma: materia.turma,
        materia: materia.materia,
        presencas: materia.presencas,
        professor: materia.professor,
        frequencia: materia.frequencia,
        materia_id: materia.materia_id,
        notas: [{ nota: materia.nota, descricao: materia.descricao }],
      });
    }
    return acc;
  }, []);

  return {
    ...obj,
    materias: transformedMaterias,
  };
};

export function* getNotas() {
  try {
    const response = yield callHandler(api.get, '/notas');
    const transformedResponse = response.data.map((obj) =>
      transformResultToView(obj)
    );
    yield put(getNotasSuccess(transformedResponse));
  } catch (error) {
    yield put(getNotasFailure());
    toast.error(
      'Falha ao tentar carregar as notas, favor contactar o suporte.'
    );
  }
}

export default all([takeLatest('@notas/REQUEST_NOTAS', getNotas)]);
